import React, { createContext, useEffect, useReducer, useState } from 'react'
import Parse from '../vendor/Parse'

const getUser = () => {
    if (typeof window !== 'undefined') {
        const user = Parse.User.current();
        return user && user.toJSON() || null;
    } else {
        return null;
    }
}

const getEnv = () => {
    if (typeof window !== 'undefined') {
        return window.location.hostname === 'localhost' ? "dev" : "prod";
    } else {
        return 'prod';
    }
}

const register = async (email, password) => {
    return await Parse.Cloud.run('createUser', {
        email,
        password
    })
}

const AuthContext = createContext({
 
})

export const AuthProvider = ({ children }) => {

    const [isLogin, setIsLogin] = useState(getUser())
    const [message, setMessage] = useState("")
    const logout = async () => {
        if (typeof window !== 'undefined') {
            await Parse.User.logOut();
            setIsLogin(getUser())
        } else {
            return null;
        }
    }

    const login = async (username, password) => {
        if (typeof window !== 'undefined') {
            const user = await Parse.User.logIn(username, password)
            setIsLogin(getUser())
        } else {
            return null;
        }
    }

    return (
        <AuthContext.Provider
            value={{
                env: getEnv(),
                logout,
                isLogin,
                register,
                getUser,
                login,
                user: isLogin,
                setMessage
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
