import React, { useState } from "react";
import { AuthProvider } from './src/contexts/AppContext'
import "@fortawesome/fontawesome-svg-core/styles.css"
import "./src/scss/volt.scss";




export const wrapRootElement = ({ element }) => (

    <AuthProvider>
        {element}
    </AuthProvider>

)